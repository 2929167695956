<template>
    <painel titulo="RdStation - Leads" icone="pi pi-comments" :refreshFunction="atualizar" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Interação (DE)</label>
                    <Calendar v-model="dataInteracaoDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Interação (ATÉ)</label>
                    <Calendar v-model="dataInteracaoAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Status SAS CPF</label>
                    <Dropdown v-model="statusCpfSas" :options="statusSas" optionLabel="descricao" optionValue="id" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Status SAS CNPJ</label>
                    <Dropdown v-model="statusCnpjSas" :options="statusSas" optionLabel="descricao" optionValue="id" />
                </div>
            </div>
        </Panel>
        <tabela
            :data="leads"
            dataKey="leadRegistroId"
            :globalFilterFields="['leadId', 'descricaoAtendimento', 'cpf', 'cnpj', 'nome', 'statusDescr']"
            stateKey="dt-rdstation-leads"
            refKey="rdstationleads"
            :mostrarExportar="true"
        >
            <template #botoes></template>
            <template #botoes2></template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :lead="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="dataCadastro" header="Recebido em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="leadId" header="Lead Id" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.leadId }}
                    </template>
                </Column>
                <Column field="dataInteracao" header="Data Interação" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataInteracao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="descricaoAtendimento" header="Descrição Atendimento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricaoAtendimento }}
                    </template>
                </Column>
                <Column field="cpf" header="CPF" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap" v-if="slotProps.data.cpf">
                            {{ slotProps.data.cpf }}
                        </div>
                        <status status="CPF NÃO RECEBIDO" v-if="!slotProps.data.cpf"></status>
                    </template>
                </Column>
                <Column field="cpfSasOkDescr" header="SAS CPF" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.cpfSasOkDescr"></status>
                    </template>
                </Column>
                <Column field="cnpj" header="CNPJ" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap" v-if="slotProps.data.cnpj">
                            {{ slotProps.data.cnpj }}
                        </div>
                        <status status="CNPJ NÃO RECEBIDO" v-if="!slotProps.data.cnpj"></status>
                    </template>
                </Column>
                <Column field="cnpjSasOkDescr" header="SAS CNPJ" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.cnpjSasOkDescr"></status>
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                        <status status="NOME NÃO RECEBIDO" v-if="!slotProps.data.nome"></status>
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import LeadsServices from './services';
import BtnDetalhar from './BtnDetalhar';
import { leadStatus } from './LeadStatus';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            leads: null,
            dataInteracaoAte: null,
            dataInteracaoDe: null,
            carregado: false,
            statusCpfSas: 0,
            statusCnpjSas: 0,
            statusSas: [
                { id: 0, descricao: 'TODOS' },
                { id: 1, descricao: 'OK' },
                { id: 2, descricao: 'NOK' },
            ],
        };
    },

    methods: {
        obterLeads() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataInteracaoDe: this.$moment(this.dataInteracaoDe).format('YYYY-MM-DD'),
                dataInteracaoAte: this.$moment(this.dataInteracaoAte).format('YYYY-MM-DD'),
                statusCpfSas: this.statusCpfSas,
                statusCnpjSas: this.statusCnpjSas,
            };
            LeadsServices.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.leads = response.data;
                } else {
                    this.leads = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterLeads();
        },

        mostrarStatusSas(dado, status) {
            return dado && status != leadStatus.RECEBIDO;
        },
    },

    created() {
        this.dataInteracaoAte = new Date();
        this.dataInteracaoDe = new Date(this.$moment(this.dataInteracaoAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterLeads();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Leads';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Leads') {
                if (this.$store.getters.atualizar) {
                    this.obterLeads();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        dataInteracaoDe() {
            if (this.carregado) {
                this.obterLeads();
            }
        },

        dataInteracaoAte() {
            if (this.carregado) {
                this.obterLeads();
            }
        },

        statusCpfSas() {
            if (this.carregado) {
                this.obterLeads();
            }
        },

        statusCnpjSas() {
            if (this.carregado) {
                this.obterLeads();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
